<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!resellerInvoice">
      <span class="loader"></span>
    </span>
    <div v-if="resellerInvoice" class="elite-tabs-wrapper">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="global"
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            <i class="ni ni-cloud-upload-96" />
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <reseller-invoice-view-global :resellerInvoice="resellerInvoice" />
        </tab-pane>

        <tab-pane title="details" id="2" :active="false">
          <span slot="title">
            <i class="ni ni-bullet-list-67" />
            {{ $t("COMMON.DETAILS") }}
          </span>
          <reseller-invoice-view-details
            :resellerInvoice="resellerInvoice"
            @resellerInvoiceItemsUpdated="get"
          />
        </tab-pane>

        <tab-pane
          title="payments"
          id="3"
          :active="false"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_RESELLER_PAYMENTS)"
        >
          <span slot="title">
            <i class="ni ni-money-coins"></i>
            {{ $t("COMMON.PAYMENTS") }}
          </span>
          <reseller-invoice-view-payments :resellerInvoice="resellerInvoice" />
        </tab-pane>

        <tab-pane
          title="logs"
          id="999"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            <i class="fa fa-file"></i>
            {{ $t("COMMON.LOGS") }}
          </span>
          <reseller-invoice-view-logs :resellerInvoice="resellerInvoice" />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import {
  INVOICE_STATUS_CANCELED,
  INVOICE_STATUS_DRAFT,
  INVOICE_STATUS_VALIDATED,
} from "@/constants/invoices";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { Tabs, TabPane } from "@/components";
import ResellerInvoiceViewGlobal from "../partials/ResellerInvoiceViewGlobal.vue";
import ResellerInvoiceViewDetails from "../partials/ResellerInvoiceViewDetails.vue";
import ResellerInvoiceViewLogs from "../partials/ResellerInvoiceViewLogs.vue";
import ResellerInvoiceViewPayments from "../partials/ResellerInvoiceViewPayments.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    ResellerInvoiceViewGlobal,
    ResellerInvoiceViewDetails,
    ResellerInvoiceViewLogs,
    ResellerInvoiceViewPayments,
  },

  mixins: [requestErrorMixin],

  props: {
    resellerInvoiceId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      resellerInvoice: null,
      INVOICE_STATUS_DRAFT: INVOICE_STATUS_DRAFT,
      INVOICE_STATUS_CANCELED: INVOICE_STATUS_CANCELED,
      INVOICE_STATUS_VALIDATED: INVOICE_STATUS_VALIDATED,
    };
  },

  computed: {
    canCancelResellerInvoice() {
      if (
        this.resellerInvoice.status === INVOICE_STATUS_CANCELED ||
        this.resellerInvoice.status === INVOICE_STATUS_DRAFT
      ) {
        return false;
      }
      if (this.resellerInvoice.status === INVOICE_STATUS_VALIDATED) {
        return true;
      }
      return false;
    },
  },

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch(
          "resellerInvoices/get",
          this.resellerInvoiceId
        );
        this.resellerInvoice =
          this.$store.getters["resellerInvoices/resellerInvoice"];
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async editResellerInvoice(row) {
      this.$router.push({
        name: "Edit ResellerInvoice",
        params: { id: this.resellerInvoice.id },
      });
    },

    async validateResellerInvoice() {
      try {
        this.loading = true;
        await this.$store.dispatch(
          "resellerInvoices/validate",
          this.resellerInvoice.id
        );
        this.resellerInvoice =
          this.$store.getters["resellerInvoices/resellerInvoice"];
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("RESELLER_INVOICES.RESELLER_INVOICE_VALIDATED"),
        });
        this.loading = false;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async cancelResellerInvoice() {
      const confirmation = await swal.fire({
        title: this.$t("RESELLER_INVOICES.CANCEL_RESELLER_INVOICE_QUESTION"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (!confirmation.value) {
        return;
      }

      try {
        this.loading = true;
        await this.$store.dispatch(
          "resellerInvoices/cancel",
          this.resellerInvoice.id
        );
        this.resellerInvoice =
          this.$store.getters["resellerInvoices/resellerInvoice"];
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("RESELLER_INVOICES.RESELLER_INVOICE_CANCELED"),
        });
        this.loading = false;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async viewResellerOrder() {
      this.$router.push(
        this.$objectViewRoute(this.resellerInvoice.resellerOrder)
      );
    },

    async addResellerPayment(row) {
      this.$router.push({
        name: "Add ResellerPayment",
        query: {
          invoice_id: this.resellerInvoice.id,
          amount: this.resellerInvoice.total_remaining_payment.toFixed(2),
        },
      });
    },
  },
};
</script>
