<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">
              {{ $t("RESELLER_INVOICES.RESELLER_INVOICES_LIST") }}
            </h3>
          </div>
          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openResellerInvoiceCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_RESELLER_INVOICES)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("RESELLER_INVOICES.ADD_RESELLER_INVOICE") }}
              </span>
            </base-button>
            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_RESELLER_INVOICES)"
              :objectType="'reseller-invoices'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>

      <reseller-invoice-list-table
        @onViewResellerInvoice="openResellerInvoiceViewModal"
        @onEditResellerInvoice="openResellerInvoiceEditModal"
        @onDeleteResellerInvoice="deleteResellerInvoice"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewResellerInvoiceModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewResellerInvoiceModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          v-if="openResellerInvoice"
          class="resizable-wrapper-content"
          v-resizable-modal="'RESELLER_INVOICE'"
          @onCloseModal="closeResellerInvoiceModal"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("RESELLER_INVOICES.VIEW_RESELLER_INVOICE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openResellerInvoice"
                :objectType="'reseller-invoices'"
                :objectId="openResellerInvoice.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  v-if="openResellerInvoice.status == INVOICE_STATUS_DRAFT"
                  class="edit"
                  @click="openResellerInvoiceEditModal(openResellerInvoice)"
                >
                  <i class="fal fa-edit"></i>
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button
                  v-if="openResellerInvoice.status == INVOICE_STATUS_DRAFT"
                  class="edit"
                  @click="deleteResellerInvoice(openResellerInvoice)"
                >
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeResellerInvoiceModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-reseller-invoice-page
              v-if="openResellerInvoice"
              :resellerInvoiceId="openResellerInvoice.id"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditResellerInvoiceModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditResellerInvoiceModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'RESELLER_INVOICE'"
          @onCloseModal="closeResellerInvoiceModal"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("RESELLER_INVOICES.EDIT_RESELLER_INVOICE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button
                class="close"
                @click="openResellerInvoiceViewModal(openResellerInvoice)"
              >
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-reseller-invoice-page
              v-if="openResellerInvoice"
              :resellerInvoiceId="openResellerInvoice.id"
              @onViewResellerInvoice="openResellerInvoiceViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddResellerInvoiceModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddResellerInvoiceModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'RESELLER_INVOICE'"
          @onCloseModal="closeResellerInvoiceModal"
        >
          <div class="resizable-wrapper-content-header">
            <h1>
              {{ $t("RESELLER_INVOICES.ADD_RESELLER_INVOICE") }}
            </h1>
            <button class="close" @click="closeResellerInvoiceModal">
              <i class="fal fa-times"></i>
            </button>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-reseller-invoice-page
              @onViewResellerInvoice="openResellerInvoiceViewModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import { INVOICE_STATUS_DRAFT } from "@/constants/invoices";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import ResellerInvoiceListTable from "./partials/ResellerInvoiceListTable.vue";
import EditResellerInvoicePage from "./components/EditResellerInvoiceComponent.vue";
import AddResellerInvoicePage from "./components/AddResellerInvoiceComponent.vue";
import ViewResellerInvoicePage from "./components/ViewResellerInvoiceComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    ResellerInvoiceListTable,
    NotificationSubscription,
    EditResellerInvoicePage,
    AddResellerInvoicePage,
    ViewResellerInvoicePage,
    [Button.name]: Button,
  },

  mixins: [],

  computed: {},

  watch: {},

  data() {
    const locationId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewResellerInvoiceModalOpened = false;
    let isEditResellerInvoiceModalOpened = false;
    let isAddResellerInvoiceModalOpened = false;
    let openResellerInvoice = null;
    if (locationId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewResellerInvoiceModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditResellerInvoiceModalOpened = true;
      }
      openResellerInvoice = { id: locationId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddResellerInvoiceModalOpened = true;
    }
    return {
      isViewResellerInvoiceModalOpened: isViewResellerInvoiceModalOpened,
      isEditResellerInvoiceModalOpened: isEditResellerInvoiceModalOpened,
      isAddResellerInvoiceModalOpened: isAddResellerInvoiceModalOpened,
      openResellerInvoice: openResellerInvoice,
      renderKey: 1,
      INVOICE_STATUS_DRAFT: INVOICE_STATUS_DRAFT,
    };
  },

  methods: {
    openResellerInvoiceCreateModal() {
      this.closeResellerInvoiceModal();
      this.isAddResellerInvoiceModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List ResellerInvoices",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openResellerInvoiceViewModal(resellerinvoice, reRender = false) {
      this.closeResellerInvoiceModal();
      this.openResellerInvoice = resellerinvoice;
      this.isViewResellerInvoiceModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List ResellerInvoices",
          query: {
            id: this.openResellerInvoice.id,
            action: QUERY_ACTIONS_VIEW,
          },
        }).href
      );
    },
    openResellerInvoiceEditModal(resellerinvoice) {
      this.closeResellerInvoiceModal();
      this.openResellerInvoice = resellerinvoice;
      this.isEditResellerInvoiceModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List ResellerInvoices",
          query: {
            id: this.openResellerInvoice.id,
            action: QUERY_ACTIONS_EDIT,
          },
        }).href
      );
    },
    closeResellerInvoiceModal() {
      this.isAddResellerInvoiceModalOpened = false;
      this.isViewResellerInvoiceModalOpened = false;
      this.isEditResellerInvoiceModalOpened = false;
      this.openResellerInvoice = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List ResellerInvoices",
          query: {},
        }).href
      );
    },

    async deleteResellerInvoice(resellerInvoice) {
      const confirmation = await swal.fire({
        title: this.$t("RESELLER_INVOICES.DELETE_THIS_RESELLER_INVOICE"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch(
            "resellerInvoices/destroy",
            resellerInvoice.id
          );
          this.renderKey++;
          this.closeResellerInvoiceModal();
          this.$notify({
            type: "success",
            timeout: 3000,
            message: this.$t("RESELLER_INVOICES.RESELLER_INVOICE_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
